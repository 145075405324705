<template>
  <nav :class="[isCollapse ? 'nosystemmanagement systemmanagement' : 'systemmanagement']">
    <div class="top-nav">
      <div class="system-introduct">
        <p class="title" v-if="!isCollapse">校长信箱管理系统</p>
        <p class="title" v-if="isCollapse">信箱</p>
      </div>
      <div class="nav-top">
        <div class="open ico" @click="open_close">
          <i v-if="!isCollapse" class="el-icon-s-fold"></i>
          <i v-if="isCollapse" class="el-icon-s-unfold"></i>
        </div>
        <div class="home" @click="home">返回首页</div>
      </div>
    </div>
    <div class="left">
      <el-menu default-active="1-4-1" class="el-menu-vertical-demo menu" :collapse="isCollapse">
        <router-link active-class="actives" :to="{name: 'ClassifyManagement'}">
          <el-menu-item index="1">
            <i class="el-icon-s-claim"></i>
            <span slot="title">
              分类管理
            </span>
          </el-menu-item>
        </router-link>
        <router-link active-class="actives" :to="{name: 'MailboxManagement'}">
          <el-menu-item index="2">
            <i class="el-icon-s-comment"></i>
            <span slot="title">
              邮件管理
            </span>
          </el-menu-item>
        </router-link>
        <router-link active-class="actives" :to="{name: 'UserMessage'}">
          <el-menu-item index="3">
            <i class="el-icon-user-solid"></i>
            <span slot="title">
              个人资料
            </span>
          </el-menu-item>
        </router-link>
        <router-link active-class="actives" :to="{name: 'MyReplay'}">
          <el-menu-item index="4">
            <i class="el-icon-s-claim"></i>
            <span slot="title">
              我的回复
            </span>
          </el-menu-item>
        </router-link>
      </el-menu>
    </div>
    <div class="right">
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'SystemManagement',
  data() {
    return {
      isCollapse: false
    };
  },
  methods: {
    // 左侧导航的打开和关闭
    open_close() {
      this.isCollapse = !this.isCollapse;
    },
    // 返回首页
    home(){
      this.$router.push({
        name: 'IntroducePage'
      });
    }
  }
};
</script>
<style>
/* stylelint-disable-next-line selector-class-pattern */
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}
</style>
<style lang="scss" scoped>
$padding: 60px;
$color: #409eff;
.actives > li,
.actives > li > i {
  color: #409eff !important;
}

@mixin top {
  padding-top: $padding;
}

@mixin absolute {
  top: 0;
  left: 0;
  width: 100%;
  height: $padding;
  line-height: $padding;
}
.nosystemmanagement {
  padding-left: 64px !important;
  transition: padding-left 0.3s ease-out;
}
.systemmanagement {
  position: relative;
  padding-top: 60px;
  padding-left: 200px;
  width: 100%;
  min-width: 800px;
  height: 100vh;
  transition: padding-left 0.3s ease-in;
  .top-nav {
    position: fixed;
    z-index: 999;
    padding-left: 200px;

    @include absolute;
    .system-introduct {
      position: absolute;
      top: 0;
      left: 0;
      width: 200px;
      text-align: center;
      color: #ffffff;
      background-color: rgb(64 158 255);
      line-height: 50px;
      .title {
        line-height: $padding;
      }
    }
    .nav-top {
      position: relative;
      width: 100%;
      height: $padding;
      background-color: rgb(89 168 247);
      .ico {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        width: $padding;
        text-align: center;
        background-color: #ffffff;
      }
      .home {
        position: absolute;
        top: 0;
        right: 20px;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  .left {
    @include top;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100vh;
    .menu {
      overflow-y: auto;
      height: 100%;
    }
  }
  .right {
    padding: 20px;
    width: 100%;
    .content {
      overflow-x: auto;
      overflow-y: auto;
      padding: 10px;
      width: 100%;
      min-width: 800px;
      background-color: #ffffff;
    }
  }
}
</style>
